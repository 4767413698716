<template>
  <b-row class="mt-5">
    <b-col md="6" v-if="summary && !summary.isFirst && previousSummary">
      <SummaryData
        :summary="previousSummary"
        :is-ready="isReady"
        title="Previous Summary"
        id="previous-summary"
      ></SummaryData>
    </b-col>
    <b-col md="6">
      <SummaryData
        title="Current Summary"
        :summary="summary"
        :previous-summary="previousSummary"
        :is-ready="isReady"
        id="current-summary"
        @ok="init"
      ></SummaryData>
    </b-col>
  </b-row>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import { SummaryService } from "@/core/services"

import moment from "moment"
import SummaryData from "./SummaryData.vue"
import _ from "lodash"
export default {
  name: "SummaryPreview",
  components: {
    SummaryData
  },
  data() {
    return {
      isReady: false,
      summary: null,
      previousSummary: null
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Summary" }])
  },
  methods: {
    init() {
      this.isReady = false
      this.summary = null
      this.previousSummary = null

      Promise.all([
        this.getSummary(
          this.$route.query.summaryId,
          this.$route.query.previousSummaryId
        ),
        this.getSummary(this.$route.query.previousSummaryId)
      ]).then(summary => {
        this.summary = summary[0]
        this.previousSummary = summary[1]

        if (this.summary && this.previousSummary) {
          let previousInfluencers = this.previousSummary.matchInfluencers
          let currentInfluencers = this.summary.matchInfluencers
          currentInfluencers = currentInfluencers.map(influencer => {
            return {
              ...influencer,
              isNew: previousInfluencers.filter(
                v => v.mediaId === influencer.mediaId
              ).length
                ? false
                : true
            }
          })
          currentInfluencers = _.orderBy(
            currentInfluencers,
            ["isNew", "created_at"],
            ["desc", "desc"]
          )

          this.previousSummary.matchInfluencers = _.orderBy(
            this.previousSummary.matchInfluencers,
            ["created_at"],
            ["desc"]
          )

          this.summary.matchInfluencers = currentInfluencers
        } else if (this.summary) {
          let currentInfluencers = this.summary.matchInfluencers
          currentInfluencers = _.orderBy(
            currentInfluencers,
            ["created_at"],
            ["desc"]
          )
          this.summary.matchInfluencers = currentInfluencers
        }
        this.isReady = true
      })
    },
    async getSummary(summaryId, previousSummaryId) {
      if (summaryId) {
        let summaryInfo = await SummaryService.getById(summaryId)

        if (!summaryInfo.error) {
          let data = {
            comment: summaryInfo.comment,
            from: moment(summaryInfo.fromDate).valueOf(),
            to: moment(summaryInfo.toDate).valueOf(),
            isFirst: summaryInfo.isFirst,
            isSum: summaryInfo.isSum,
            topicId: summaryInfo.topicId,
            summaryId: summaryId,
            previousSummaryId
          }

          let summary = await SummaryService.preview(data)

          return {
            ...summary,
            comment: summary.comment
              ? summary.comment.replace(/(\r\n|\n)/gm, "<br/>")
              : summary.comment,
            from_text: moment(summary.from).format("YYYY-MM-DD HH:mm"),
            to_text: moment(summary.to).format("YYYY-MM-DD HH:mm"),
            sources_engagement: Object.keys(summary.summaryEngagement.totalPost)
              .filter(
                source =>
                  source !== "all" &&
                  summary.summaryEngagement.totalPost[source] > 0
              )
              .map(
                source =>
                  `${this.$options.filters.capitalize(
                    source
                  )}: ${this.$options.filters.number_format(
                    summary.summaryEngagement.totalPost[source]
                  )}`
              )
              .join(", "),

            summaryLog: summaryInfo
          }
        }
      }
      return null
    }
  }
}
</script>

<style></style>
