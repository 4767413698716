<template>
  <b-modal
    id="confirm-sent-modal"
    ref="modal"
    title="Confirm"
    @ok="createSnapshot"
    ok-variant="primary"
    ok-title="Save"
    :ok-disabled="isProcessing"
  >
    Do you want to send this?
  </b-modal>
</template>

<script>
import { SummaryService } from "@/core/services"
export default {
  name: "ConfirmSentModal",
  props: {
    summary: {
      type: Object,
      required: true,
    },
    previousSummary: null,
  },
  data() {
    return {
      isProcessing: false,
    }
  },
  methods: {
    async createSnapshot(e) {
      e.preventDefault()
      this.isProcessing = true

      if (this.summary.summaryLog.mode !== "") {
        let response = await SummaryService.createSnapshot({
          summaryId: this.summary.summaryLog.summaryId,
          previousSummaryId: this.previousSummary
            ? this.previousSummary.summaryLog.summaryId
            : null,
        })

        if (!response.error) {
          this.$emit("ok")
          this.$bvModal.hide("confirm-sent-modal")
          this.$root.$bvToast.toast("Update status success", {
            title: `Success`,
            variant: "success",
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast("Update status fail", {
            title: `Error`,
            variant: "error",
            solid: true,
          })
        }
      }

      this.isProcessing = false
    },
  },
}
</script>

<style></style>
